import React, { useEffect, useRef, useState } from 'react';

import { usePythonConsole } from 'react-py';
import { ConsoleState } from 'react-py/dist/types/Console';

import Loader from './Loader';
import clsx from 'clsx';

import pythonInit from './pyodideInit';

const ps1 = '>>> ';
const ps2 = '... ';

export default function PyConsole() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([]);
  const [history, setHistory] = useState([]);
  const [cursor, setCursor] = useState(0);

  const {
    runPython,
    stdout,
    stderr,
    banner,
    consoleState,
    isLoading,
    isRunning,
    interruptExecution,
    isAwaitingInput,
    sendInput,
    prompt,
    isReady
  } = usePythonConsole();

  const textArea = useRef();

  // load initial monkeypatches
  useEffect(async() => {
    if(isReady) {
      await runPython(pythonInit);
    }
  }, [isReady]);

  useEffect(() => {
    banner && setOutput((prev) => [...prev, { text: 'Python 3.11.2 (Pyodide/Emscripten)\n' + 'Cryptosat SDK Ready\n'}]);
  }, [banner]);

  useEffect(() => {
    stdout && setOutput((prev) => [...prev, { text: stdout }]);
  }, [stdout]);

  useEffect(() => {
    stderr &&
      setOutput((prev) => [
        ...prev,
        { text: stderr + '\n', className: 'text-red-500' }
      ]);
  }, [stderr]);

  useEffect(() => {
    if (isLoading || isRunning) {
      textArea.current?.blur();
    }
  }, [isLoading, isRunning]);

  useEffect(() => {
    if (isAwaitingInput) {
      setInput('');
      // Remove the last line of output since we render the prompt
      setOutput((prev) => prev.slice(0, -1));
    }
  }, [isAwaitingInput]);

  function getPrompt() {
    return isAwaitingInput
      ? prompt || ps1
      : consoleState === ConsoleState.incomplete
      ? ps2
      : ps1;
  }

  async function send() {
    if (!input) return;
    setCursor(0);
    setHistory((prev) => [input, ...prev]);
    if (isAwaitingInput) {
      setOutput((prev) => [...prev, { text: getPrompt() + ' ' + input }]);
      sendInput(input);
    } else {
      setOutput((prev) => [...prev, { text: getPrompt() + input + '\n' }]);
      const runInput = input + ' ';
      setInput('');
      console.log(runInput);
      await runPython(runInput);
    }
    setInput('');
    textArea.current?.focus();
  }

  function clear() {
    setOutput([]);
  }

  function reset() {
    interruptExecution();
    clear();
  }

  return (
    <div className="relative mb-10">
      <div className="absolute right-0 z-20">
      </div>

      {isLoading && <Loader />}

      <pre className="z-10 max-h-[calc(100vh_-_20rem)] min-h-[18rem] text-left text-base shadow-md">
        {output.map((line, i) => (
          <code className={line.className} key={i}>
            {line.text}
          </code>
        ))}
        <div className="relative flex items-center" style={{backgroundColor:'rgba(0,0,0,0.3)'}}>
          <code className="text-gray-500">{getPrompt()}</code>
          <textarea
            ref={textArea}
            className={clsx(
              'commandline', 'cli',
              isLoading && 'pointer-events-none'
            )}
            style={{
              height: input
                ? `${input.split('\n').length * 1.5 + 1}rem`
                : '2.5rem',
              fontFamily: 'unset'
            }}
            value={input}
            onChange={(e) => {
              const value = e.target.value
              setHistory((prev) => [value, ...prev.slice(1)])
              setInput(value)
            }}
            onFocus={(e) => {
              const value = e.target.value
              setHistory((prev) => [value, ...prev.slice(1)])
              setInput(value)
            }}
            onKeyDown={(e) => {
              const start = e.target.selectionStart
              const end = e.target.selectionEnd

              switch (e.key) {
                case 'Enter':
                  e.preventDefault()
                  !e.shiftKey && send()
                  break
                case 'ArrowUp':
                  if (start === 0 && end === 0) {
                    setInput(history[cursor])
                    setCursor((prev) =>
                      Math.min(...[history.length - 1, prev + 1])
                    )
                  }
                  break
                case 'ArrowDown':
                  if (input && start === input.length && end === input.length) {
                    setInput(history[cursor])
                    setCursor((prev) => Math.max(...[0, prev - 1]))
                  }
                  break
                default:
                  break
              }
            }}
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
      </pre>
    </div>
  )
}
